body {
    display:flex;
    #root {
	flex-grow: 1;
	display:flex;
	flex-direction: column;
    }
    //background: #438AF7;
    //rgba(67, 138, 247, 1)
}

.navigation {
    color: white;
    background-color: "#1E1A55";
    shadow: smooth;
    display: flex;
}

.navigation-brand {
    color: white;
    background-color: "#1E1A55";
    shadow: smooth;
    display: flex;
}

.navigation-menu li {
    color: white;
    background-color: "#1E1A55";
    shadow: smooth;

}

.navigation-menu li {
    color: white;
    background-color: "#1E1A55";
    shadow: smooth;

}
.header-item-active {
    color: "#D5933A";
    background-color: "#1E1A55";
    shadow: smooth;


}

.clickable {
    cursor:pointer
}

.side-menu-hidden {
	display:     "hidden"

}


.side-menu {
	height:150px;
	background: white;
	margin-top: -150px;
	background-image: './media/images/pattern-dots.svg';
	background-size:auto 50%;
	background-position: 20px 20px;
	background-repeat:no-repeat;
	position:relative;
}


.side-menu-active {
	height:150px;
	background:white;
	margin-top: 0px ;
	background-image: './media/images/pattern-dots.svg';
	background-size:auto 50%;
	background-position: 20px 20px;
	background-repeat:no-repeat;
	position:relative;
}



.footer-box {
    background-color: white;
    padding: 20px;
    position: relative;
}

.modal-body {
    font-family: Consolas, monaco, monospace;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    //line-height: 20px;
}

.modal-col {
    font-family: Consolas, monaco, monospace;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    //line-height: 20px;
}

.search-box {
    background-color: white;
    padding: 5px;
    position: relative;
    width: 100%;
    height: 100%;
    font-family: Lato, monaco, monospace;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
}

.search-tags-select {
    background-color: white;
    padding: 0px;
    position: relative;
    width: 100%;
    height: 100%;
    font-family: Lato, monaco, monospace;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
}

.count-box {
    background-color: white;
    padding: 5px;
    position: relative;
    width: 100%;
    height: 80%;
    font-size: 1.2em;
    font-weight: 800;
}

.person-content {
    background-color: white;
    padding: 0px;
    position: relative;
    font-size: 1.0em;
}

.person-box {
    background-color: white;
    padding: 0px;
    position: relative;
    font-size: 1.0em;
}
.contact-box {
    background-color: white;
    padding: 0px;
    position: relative;
    font-size: 1.0em;
}

.th.contact {


}



.contact {
   padding: 5px;
   text-align: right;
    background-color: white;
}

.contact-highlight {
   padding: 5px;
   text-align: right;
   background-color: #ECECF0;
}

.contact-keys {
   padding: 5px;
   text-align: left;
    background-color: #ECECF0;
}

.contact-keys-highlight {
   padding: 5px;
   text-align: left;
   background-color: #D1DEF6;
}

.contact-list {
    padding: 24px;
    text-align: left;
    /*display: flex;*/
}
.contact-buttons {
    padding: 2px;
    text-align: center;
    /*display: flex;*/
}

.contact-content {
    //rgba(67, 138, 247, 1)
    background-color:rgb(208, 226, 245);
    //background-color:rgb(220, 250, 216);
    color: #504f4f;
    padding: 12px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    border-color: white;
    border-width: 2px;
}

.leaflet {
  width: 400px;
  height: 400px;
}

//.nowrap{
//    display:inline-block;
//}

.leaflet-container {
  width: 400px;
  height: 400px;
}


.login-nav {
    flex-grow: 1;
    justify: center;
    transition: width 2s;
     py: 4;
     px: 4;
     pr: 8;
     rounded: "t-md";
     color: "gray-600";
     font: "medium";
     background-color: "gray-300";
     cursor: "pointer";
}

.login-nav:hover {
     background-color: gray-100;
}

.login-nav-active {
    flex-grow: 1;
    justify: center;
    transition: width 2s;
     py: 4;
     px: 4;
     pr: 8;
     rounded: "t-md";
     color: "gray-800";
     font: "medium";
     background-color: "white";
     cursor: "pointer";
}
.login-nav-active:hover {
     background-color: white;
}

.login-box {
    transition: "mr-4";
    flex: "shrink-0";
    inline: "flex";
    items: "center";
    justify: "center";
    text:    "sm";
    height: 6;
    width: 6;
    rounded: "full";
    background-color: "gray-200";
    color: "gray";

}

.login-box-active {
    transition: "mr-4";
    flex: "shrink-0";
    inline: "flex";
    items: "center";
    justify: "center";
    text:    "sm";
    height: 6;
    width: 6;
    rounded: "full";
    background-color: "green-300";
    color: "green";
}

.login-box-icon {
    transition: "h-4";
    height: 4;
    width: 4;
    background-color: "gray-400";
}

.login-box-icon-active {
    transition: "h-4";
    height: 4;
    width: 4;
    background-color: "green-600";
}





._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}


@media only screen and (max-width: 825px) {
    .brand-logo {
        width: 90%;
    }
}


